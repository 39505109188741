<style scoped>
    #reset-password-background {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        object-fit: cover;
        object-position: center;
    }

    #password-changed {
        padding: 20px 10px;
    }
</style>

<template>
    <picture>
        <source srcset="../../assets/images/login-background.webp" type="image/webp">
        <source srcset="../../assets/images/login-background.jpg" type="image/jpg">
        <img id="reset-password-background" src="../../assets/images/login-background.webp" alt="Photo de broche">
    </picture>

    <Modal>
        <template v-slot:modalIcon>
            <font-awesome-icon :icon="['fas', 'key']" />
        </template>
        <template v-slot:modalTitle>
            RÉINITIALISER VOTRE MOT DE PASSE
        </template>
        <template v-slot:modalContent>
            <form v-if="!passwordChanged">
                <div>
                    <BaseInput v-model="password" label="Mot de passe" type="password" name="password1" validator="password" :error="formErrors?.password1?.error.message" @onChange="onInputChange" :displayError="displayError" />
                </div>
                <div>
                    <BaseInput v-model="passwordConfirm" label="Confirmer le mot de passe" type="password" name="password2" validator="text" :error="formErrors?.password2?.error.message" @onChange="onInputChange" :displayError="displayError" />
                </div>
            </form>
            <div id="password-changed" v-if="passwordChanged">
                Vous pouvez désormais vous connecter avec votre nouveau mot de passe.<br/>Vous allez être redirigé vers la page d'authentification
            </div>
        </template>
        <template v-slot:modalButtons>
            <BaseButton v-if="!passwordChanged" class="orange-button" @click="submitModal" buttonText="Valider">
                <template v-slot:iconBefore>
                    <font-awesome-icon :icon="['fas', 'check']" />
                </template>
            </BaseButton>
        </template>
    </Modal>
</template>

<script>
    import BaseButton from '../../components/Base/BaseButton'
    import BaseInput from '../../components/Base/BaseInput'
    import Modal from '../../components/App/Modal'
    import axios from 'axios'

    export default {
        name: 'ResetPassword',
        components: {
            BaseButton,
            BaseInput,
            Modal
        },
        data() {
            return {
                passwordChanged: false,
                password: null,
                passwordConfirm: null,
                displayError: false,
                formErrors: {}
            }
        },
        methods: {
            submitModal() {
                if (!this.checkForm()) return;

                axios
                .post('account/resetPassword', {
                    resetPasswordToken: this.$route.query.resetPasswordToken,
                    password: this.password
                }, { 
                    toastError: true, 
                    showPreloader: true 
                })
                .then(() => {
                    this.passwordChanged = true;

                    setTimeout(() => {
                        this.$router.push({ path: '/connexion' });
                    }, 6000);
                })
                .catch(() => {});
            },
            onInputChange(input) {
                if (input.error.message !== null) {
                    this.formErrors[input.name] = input;
                } else {
                    delete this.formErrors[input.name];
                }

                // En édition, si les champs "Mot de passe" ne sont pas saisis, on n'affiche pas d'erreur
                let fieldPassword1 = this.formErrors?.password1?.value?.trim() || null;
                let fieldPassword2 = this.formErrors?.password2?.value?.trim() || null;

                if (this.createOrEditMode === 'edit') {
                    if ((fieldPassword1 == null || fieldPassword1.length === 0) && (fieldPassword2 == null || fieldPassword2.length === 0)) {
                        delete this.formErrors.password1;
                        delete this.formErrors.password2;
                    }
                }

                // Gestion des erreurs personalisées
                if (!this.formErrors.password1 || this.formErrors.password1.error?.type === 'custom') {
                    // Si les mots de passe sont différents
                    if (this.password != this.passwordConfirm) {
                        this.formErrors.password1 = {
                            name: 'password1',
                            value: this.password,
                            error: {
                                type: 'custom',
                                message: `Le mot de passe est différent de la confirmation`
                            }
                        }
                    } else {
                        delete this.formErrors.password1;
                    }
                }
            },
            checkForm() {
                this.displayError = true;

                return Object.keys(this.formErrors).length === 0;
            }
        }
    }
</script>